.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #00000077;

  align-items: center;
  justify-content: center;
  display: flex;
  user-select: none;
}

.modal-inner {
  background-color: var(--light);
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.modal-inner h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.modal-inner p {
  font-size: 1.25rem;
}

.modal-inner button {
  background-color: var(--green);
  color: var(--light);
  font-size: 1.5rem;
  padding: 0.5rem;
  margin: 1rem 0 0.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}
